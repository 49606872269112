import useGatekeeper from "@/hooks/useGatekeeper";
import Dropdown from "@/ui-lib/components/Dropdown";
import { Theme, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column, createColumnHelper } from "@tanstack/react-table";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import { ResourceType } from "@ternary/api-lib/constants/enums";
import { MeasureCell } from "@ternary/api-lib/ui-lib/charts/Table/MeasureCell";
import Table, {
  ActionMenuButton,
} from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Flex from "@ternary/web-ui-lib/components/Flex";
import {
  formatCurrencyRounded,
  formatNumberRounded,
} from "@ternary/web-ui-lib/utils/formatNumber";
import React, { useMemo } from "react";
import LockedWrapper from "../../../ui-lib/components/LockedWrapper";
import copyText from "../copyText";
import { Budget } from "../types";
import { getAmountFromBudget } from "../utils";
import BudgetRatioBar from "./BudgetRatioBar";

export const BUDGET_SOURCE_GOOGLE = "google";

interface Props {
  budgets: Budget[];
  loading: boolean;
  selectedBudget?: Budget;
  onAddToDashboard: (id: string) => void;
  onSelectBudget: (id: string) => void;
  onSelectCopy: (id: string) => void;
  onSelectDelete: (id: string) => void;
  onSelectEdit: (id: string) => void;
}

export type TableData = {
  id: string;
  actualStatus: { total: number; done: number };
  amount: number | string | null;
  createdByID: string;
  forecastedStatus: number | string;
  name: string;
  resourceID: string;
  resourceName: string;
  source: string | null;
  variance: { absolute: number; percent: number } | string;
};

interface BudgetDropdownProps {
  budgetID: string;
  createdByID: string;
  disabled: boolean;
  source: string | null;
  onAddToDashboard: (id: string) => void;
  onSelectCopy: (id: string) => void;
  onSelectDelete: (id: string) => void;
  onSelectEdit: (id: string) => void;
}

const columnHelper = createColumnHelper<TableData>();

export default function BudgetsTable(props: Props): JSX.Element {
  const theme = useTheme();

  const gatekeeper = useGatekeeper();

  function BudgetDropdown(dropdownProps: BudgetDropdownProps): JSX.Element {
    const options = [
      ...(dropdownProps.source !== BUDGET_SOURCE_GOOGLE
        ? [
            {
              label: copyText.actionEdit,
              locked: !gatekeeper.canUpdateBudgets,
              onClick: () => dropdownProps.onSelectEdit(dropdownProps.budgetID),
            },
          ]
        : []),
      {
        label: copyText.actionCopy,
        locked: !gatekeeper.canCreateBudgets,
        onClick: () => dropdownProps.onSelectCopy(dropdownProps.budgetID),
      },
      ...(dropdownProps.source !== BUDGET_SOURCE_GOOGLE
        ? [
            {
              label: copyText.actionDelete,
              locked: !gatekeeper.getCanDeleteSpecificBudget(
                dropdownProps.createdByID
              ),
              onClick: () =>
                dropdownProps.onSelectDelete(dropdownProps.budgetID),
            },
          ]
        : []),
      {
        label: copyText.actionAddToDashboard,
        locked:
          !gatekeeper.canUpdateDashboard && !gatekeeper.canCreateDashboards,
        onClick: () => dropdownProps.onAddToDashboard(dropdownProps.budgetID),
      },
    ];

    return (
      <LockedWrapper locked={dropdownProps.disabled}>
        <Dropdown
          disabled={dropdownProps.disabled}
          options={options}
          placement="bottom-end"
        >
          <ActionMenuButton />
        </Dropdown>
      </LockedWrapper>
    );
  }

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "budgetSource",
        cell: ({ row }) => {
          return (
            <Box maxWidth={5}>
              {row.original.source === BUDGET_SOURCE_GOOGLE ? (
                <Tooltip content={copyText.budgetsTableSourceTooltipGoogle}>
                  <FontAwesomeIcon icon={faGoogle} />
                </Tooltip>
              ) : null}
            </Box>
          );
        },
        header: "",
        meta: { align: "center" },
        size: 10,
      }),
      columnHelper.accessor("name", {
        header: copyText.budgetsTableHeaderName,
        meta: { truncate: true },
        size: 300,
      }),
      columnHelper.accessor("amount", {
        cell: ({ getValue, column }) => {
          const value = getValue();
          if (typeof value === "number") {
            return (
              <MeasureCell
                applyMaxCharacters
                columnID={column?.id}
                unit={UnitType.CURRENCY}
                value={value}
              />
            );
          }

          return copyText.na;
        },
        header: copyText.budgetsTableHeaderAmount,
        meta: { align: "right" },
        sortDescFirst: true,
        sortingFn: "basic",
        size: 120,
      }),
      columnHelper.accessor("actualStatus", {
        cell: function renderStatus({ column, getValue }) {
          const value = getValue();
          if (typeof value === "string") return value;
          return (
            <Flex width="60%">
              <BudgetRatioBar column={column} values={value} />
            </Flex>
          );
        },
        header: copyText.budgetsTableHeaderActual,
        meta: { align: "right" },
        size: 200,
        sortingFn: (rowA, rowB) => {
          const doneA = rowA.original.actualStatus.done;
          const doneB = rowB.original.actualStatus.done;
          return doneA < doneB ? -1 : doneA > doneB ? 1 : 0;
        },
      }),
      columnHelper.accessor("forecastedStatus", {
        cell: ({ column, getValue }) => {
          const value = getValue();
          if (typeof value === "number") {
            return (
              <MeasureCell
                applyMaxCharacters
                columnID={column?.id}
                unit={UnitType.CURRENCY}
                value={value}
              />
            );
          }

          return value;
        },
        header: copyText.budgetsTableHeaderProjected,
        meta: { align: "right" },
        size: 120,
        sortDescFirst: true,
        sortingFn: "basic",
      }),
      columnHelper.accessor("variance", {
        cell: ({ column, getValue }) => {
          const value = getValue();
          return (
            <>
              {typeof value === "string"
                ? value
                : isFinite(value.percent)
                  ? renderVariance({
                      ...value,
                      column,
                      showTableFormatter: true,
                      theme,
                    })
                  : "(-)"}
            </>
          );
        },
        header: copyText.budgetsTableHeaderVariance,
        meta: { align: "right" },
        sortDescFirst: true,
        size: 200,
        sortingFn: (rowA, rowB) => {
          if (typeof rowA.original.variance === "string") {
            return -1;
          }
          if (typeof rowB.original.variance === "string") {
            return 1;
          }

          if (
            rowA.original.variance.absolute > rowB.original.variance.absolute
          ) {
            return 1;
          }
          if (
            rowB.original.variance.absolute > rowA.original.variance.absolute
          ) {
            return -1;
          }

          return 0;
        },
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: ({ row }) => (
          <BudgetDropdown
            budgetID={row.original.id}
            createdByID={row.original.createdByID}
            disabled={
              !gatekeeper.canUpdateBudgets &&
              !gatekeeper.canCreateBudgets &&
              !gatekeeper.getCanDeleteSpecificBudget(row.original.createdByID)
            }
            source={row.original.source}
            onAddToDashboard={props.onAddToDashboard}
            onSelectEdit={props.onSelectEdit}
            onSelectCopy={props.onSelectCopy}
            onSelectDelete={props.onSelectDelete}
          />
        ),
        header: "",
        meta: { align: "center" },
        size: 40,
      }),
    ],
    [props.selectedBudget]
  );

  const data: TableData[] = useMemo(
    () =>
      props.budgets.map((budget) => {
        const amount = getAmountFromBudget(budget);
        const forecastDelta =
          budget.status !== null &&
          budget.status !== undefined &&
          (amount || amount === 0)
            ? budget.status.forecasted - amount
            : null;

        const statusNotReported =
          !budget.amount.specifiedAmount && !budget.amount.variableAmount;

        const fallbackStatus = statusNotReported
          ? copyText.statusNotReported
          : copyText.statusPending;

        return {
          id: budget.id,
          actualStatus:
            budget.status !== null &&
            budget.status !== undefined &&
            (amount || amount === 0)
              ? { done: budget.status.actual, total: amount }
              : { done: 0, total: 0 },
          amount: statusNotReported ? copyText.na : amount,
          createdByID: budget.createdByID,
          forecastedStatus:
            budget.status !== null && budget.status !== undefined
              ? budget.status.forecasted
              : fallbackStatus,
          measure: budget.measure,
          name: budget.name,
          resourceID: budget.id,
          resourceContext: { forecast: budget.status?.forecasted },
          resourceName: budget.name,
          source: budget.source,
          variance:
            budget.status !== null &&
            budget.status !== undefined &&
            (amount || amount === 0) &&
            (forecastDelta || forecastDelta === 0)
              ? {
                  percent: (forecastDelta / amount) * 100,
                  absolute: forecastDelta,
                }
              : fallbackStatus,
        };
      }),
    [props.budgets]
  );

  return (
    <Table
      clickableRows
      columns={columns}
      data={data}
      initialState={{ sorting: [{ id: "amount", desc: true }] }}
      isLoading={props.loading}
      resourceType={ResourceType.BUDGET}
      selectedRowID={props.selectedBudget?.id}
      showPagination
      sortable
      onClick={(row) => props.onSelectBudget(row.id)}
    />
  );
}

const StyledVariance = styled.div<{ color: string }>`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.color};
  font-weight: bold;

  div:nth-of-type(2) {
    font-weight: normal;
    margin-left: 0.5rem;
    min-width: 3rem;
  }
`;

export const renderVariance = (params: {
  absolute: number;
  column?: Column<TableData, unknown>;
  percent: number;
  showTableFormatter?: boolean;
  theme: Theme;
}): JSX.Element => {
  return (
    <StyledVariance
      color={
        params.absolute > 0
          ? params.theme.feedback_negative
          : params.theme.feedback_positive
      }
    >
      <div>
        {params.showTableFormatter ? (
          <MeasureCell
            applyMaxCharacters
            columnID={params.column?.id}
            unit={UnitType.CURRENCY}
            value={params.absolute}
          />
        ) : (
          formatCurrencyRounded({
            number: params.absolute,
          })
        )}
      </div>
      <div>({formatNumberRounded(params.percent) + copyText.unitPercent})</div>
    </StyledVariance>
  );
};
