import { UpdateJiraIntegrationParams } from "@/api/core/types";
import paths from "@/constants/paths";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import { useConfig } from "@/context/ConfigProvider";
import useGetJiraIntegrationByTenantID from "@/features/admin/hooks/useGetJiraIntegrationByTenantID";
import useUpdateJiraIntegration from "@/features/admin/hooks/useUpdateJiraIntegration";
import Form from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import { AlertType, postAlert } from "@/utils/alerts";
import { useTheme } from "@emotion/react";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { FormApi, useForm } from "@tanstack/react-form";
import { SocialIntegrationType } from "@ternary/api-lib/constants/enums";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { get, isEqual } from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";
import useAuthenticatedUser from "../../../../../hooks/useAuthenticatedUser";
import { useNavigateWithSearchParams } from "../../../../../lib/react-router";
import copyText from "../../copyText";
import { _defaultValues } from "./constants";
import JiraIntegrationForm from "./JiraIntegrationForm";
import { FormData } from "./types";

type JiraIntegration = {
  apiTokenPreview: string;
  email: string;
  issueTransitionID: string | null;
  projectID: string;
};

export function JiraIntegrationFormContainer() {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const config = useConfig();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  //
  // State
  //

  const jiraIntegrationLocation: JiraIntegration | undefined = get(
    location.state,
    "integration"
  );

  //
  // Queries
  //

  const {
    data: jiraIntegrationServer,
    isLoading: isLoadingJiraIntegrationServer,
    refetch: refetchJiraIntegration,
  } = useGetJiraIntegrationByTenantID(authenticatedUser.tenant.fsDocID, {
    enabled: !jiraIntegrationLocation,
  });

  //
  // Mutations
  //

  const {
    isPending: isUpdatingJiraIntegration,
    mutate: updateJiraIntegration,
  } = useUpdateJiraIntegration({
    onSuccess: () => {
      refetchJiraIntegration();
      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successUpdatingSocialIntegrationMessage,
      });

      navigate(paths._admin, { searchParams: { tab: "integrations" } });
    },
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.jiraErrorUpdatingIntegration,
      });
    },
  });

  //
  // Computed Values
  //

  const jiraIntegration = jiraIntegrationLocation ?? jiraIntegrationServer;

  //
  // Interaction Handlers
  //

  const defaultValues = jiraIntegration
    ? getValuesFromIntegration(jiraIntegration)
    : _defaultValues;

  const handleSubmitJira = ({
    formApi,
    value,
  }: {
    value: FormData;
    formApi: FormApi<FormData>;
  }) => {
    if (jiraIntegration) {
      const defaultValues = formApi.options.defaultValues;
      const tenantID = authenticatedUser.tenant.fsDocID;
      if (!defaultValues) return;
      handleUpdateJiraIntegration(tenantID, value, defaultValues);
      return;
    } else {
      activityTracker.captureAction(actions.CLICK_ADMIN_CREATE_INTEGRATION, {
        type: SocialIntegrationType.JIRA,
      });
      const searchParams = new URLSearchParams({
        returnURL:
          window.location.origin +
          window.location.pathname +
          "?tab=integrations",
        tenantID: authenticatedUser.tenant.fsDocID,
        apiToken: value.apiToken,
        email: value.email,
        projectID: value.projectID,
        ...(value.issueTransitionID
          ? { customTransitionID: value.issueTransitionID }
          : {}),
      });
      const redirectURL = `${
        config.JIRA_API_BASE_URL
      }/connect?${searchParams.toString()}`;
      window.open(redirectURL, "_blank");
      return;
    }
  };

  function handleUpdateJiraIntegration(
    tenantID: string,
    values: FormData,
    defaultValues: FormData
  ) {
    const changes = getChanges(tenantID, values, defaultValues);

    activityTracker.captureAction(actions.CLICK_ADMIN_UPDATE_INTEGRATION, {
      type: SocialIntegrationType.JIRA,
    });

    updateJiraIntegration(changes);
  }

  const form = useForm({
    defaultValues: defaultValues,
    onSubmit: handleSubmitJira,
  });

  return (
    <Flex direction="column" height="80vh">
      <Button
        iconStart={<Icon icon={faArrowLeft} />}
        secondary
        width={200}
        onClick={() =>
          navigate(paths._admin, { searchParams: { tab: "integrations" } })
        }
      >
        {copyText.backToIntegrationsButtonLabel}
      </Button>
      <Text marginVertical={theme.space_md} appearance="h3">
        {copyText.jiraIntegrationTitle}
      </Text>

      <Flex height="100%" width="100%">
        {isLoadingJiraIntegrationServer ? (
          <EmptyPlaceholder loading width="50%" />
        ) : (
          <Form
            flex
            width="55%"
            onSubmit={(event) => {
              event.preventDefault();
              event.stopPropagation();
              form.handleSubmit();
            }}
          >
            <JiraIntegrationForm form={form} />
            <form.Subscribe
              children={(state) => {
                let disabled =
                  !state.canSubmit ||
                  !state.isTouched ||
                  isLoadingJiraIntegrationServer ||
                  isUpdatingJiraIntegration;
                if (jiraIntegration) {
                  const hasChanged = getHasChanged(
                    state.values,
                    form.options.defaultValues
                  );

                  disabled = disabled || !hasChanged;
                }

                return (
                  <Button
                    marginTop={theme.space_sm}
                    disabled={disabled}
                    primary
                    type="submit"
                    width={100}
                  >
                    {isUpdatingJiraIntegration ? (
                      <LoadingSpinner />
                    ) : (
                      copyText.submitButtonLabel
                    )}
                  </Button>
                );
              }}
            />
          </Form>
        )}
      </Flex>
    </Flex>
  );
}

function getValuesFromIntegration(integration: JiraIntegration) {
  return {
    apiToken: integration.apiTokenPreview,
    email: integration.email,
    issueTransitionID: integration.issueTransitionID ?? "",
    projectID: integration.projectID,
  };
}

function getChanges(
  tenantID: string,
  values: FormData,
  defaultValues: FormData
) {
  const changes: UpdateJiraIntegrationParams = {
    tenantID,
  };

  if (!isEqual(defaultValues.apiToken, values.apiToken)) {
    changes.apiToken = values.apiToken;
  }
  if (!isEqual(defaultValues.email, values.email)) {
    changes.email = values.email;
  }
  if (!isEqual(defaultValues.issueTransitionID, values.issueTransitionID)) {
    changes.issueTransitionID = values.issueTransitionID;
  }
  return changes;
}

function getHasChanged(values, defaultValues) {
  return Object.keys(values).some(
    (key) => !isEqual(values[key], defaultValues[key])
  );
}
