import { useTheme } from "@emotion/react";
import { faEllipsisV, faTimeline } from "@fortawesome/free-solid-svg-icons";
import { Row, createColumnHelper } from "@tanstack/react-table";
import {
  CostAlertType,
  DataSource,
  ResourceType,
} from "@ternary/api-lib/constants/enums";
import { LabelMapsEntity } from "@ternary/api-lib/core/types";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatCurrency } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import React, { useMemo } from "react";
import {
  AnomalyDetection,
  Forecasting,
  Threshold,
} from "../../../api/core/types";
import GroupingTag from "../../../components/GroupingTag";
import GroupingTooltipContent from "../../../components/GroupingTooltip";
import useGatekeeper from "../../../hooks/useGatekeeper";
import Dropdown from "../../../ui-lib/components/Dropdown";
import copyText from "../copyText";
import {
  CostAlertDimension,
  CostAlertFilters,
  CostAlertLegacySourceRule,
} from "../types";
import {
  getDelta,
  getSourceAlertRuleName,
  getStringifiedCostAlertType,
  getStringifiedDelta,
  getStringifiedRange,
} from "../utils";

type CostAlert = {
  id: string;
  alertRuleID: string;
  createdAt: string;
  dimensions: CostAlertDimension[];
  eventValue: number;
  expectedValue: {
    upperBound: number;
    lowerBound: number;
  } | null;
  eventType: CostAlertType;
  eventTime: string;
  alertRule?: {
    id: string;
    condition: AnomalyDetection | Forecasting | Threshold;
    name: string;
    dataSource: DataSource;
    dimensions: string[];
  };
};

type TableData = {
  id: string;
  alertRuleID: string;
  delta: number;
  dimensionGrouping: CostAlertDimension[];
  actualValue: number;
  occurredAt: string;
  eventType: CostAlertType;
  expectedRange: number[];
  sourceAlertRuleName: string;
  alertedAt: string;
};

interface Props {
  costAlerts: CostAlert[];
  isLoading: boolean;
  labelMaps?: LabelMapsEntity;
  onInteraction: (interaction: CostAlertTable.Interaction) => void;
}

const columnHelper = createColumnHelper<TableData>();

export function CostAlertTable(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "eventFeedNavigator",
        cell: ({ row }) => {
          {
            return row.original.alertRuleID !==
              CostAlertLegacySourceRule.LEGACY_BIGQUERY_ANOMALY_ML &&
              row.original.alertRuleID !==
                CostAlertLegacySourceRule.LEGACY_BILLING_ANOMALY_ML ? (
              <Tooltip content={copyText.actionViewEventStream}>
                <Button
                  disabled={
                    !gatekeeper.canListCostAlerts ||
                    !gatekeeper.canListAlertRuleEvents
                  }
                  iconStart={<Icon icon={faTimeline} rotation={90} />}
                  primary
                  size="tiny"
                  onClick={() =>
                    props.onInteraction({
                      type: CostAlertTable.INTERACTION_VIEW_EVENT_FEED_CLICKED,
                      alertRuleID: row.original.alertRuleID,
                    })
                  }
                />
              </Tooltip>
            ) : null;
          }
        },
        meta: { align: "center" },
        size: 20,
      }),
      columnHelper.accessor("sourceAlertRuleName", {
        cell: ({ getValue }) => {
          return (
            <Text
              color={theme.primary_color_text}
              cursor="pointer"
              marginRight={theme.space_xs}
              onClick={() =>
                props.onInteraction({
                  type: CostAlertTable.INTERACTION_FILTER_CLICKED,
                  filterKey: "sourceAlertRule",
                  filterValue: getValue(),
                })
              }
            >
              {getValue()}
            </Text>
          );
        },
        header: copyText.costAlertTableHeader_sourceAlertRule,

        size: 80,
      }),
      columnHelper.accessor("dimensionGrouping", {
        cell: ({ row }) => {
          const dimensionGrouping = row.original.dimensionGrouping;
          if (dimensionGrouping.length > 0) {
            return (
              <Box minWidth={0}>
                <Tooltip
                  content={
                    <GroupingTooltipContent
                      dimensionGrouping={dimensionGrouping}
                    />
                  }
                  delayHide={250}
                  placement="top"
                >
                  <GroupingTag dimensionGrouping={dimensionGrouping} />
                </Tooltip>
              </Box>
            );
          }
          return "--";
        },
        header: copyText.costAlertTableHeader_dimensionGrouping,

        size: 100,
        sortingFn: customGroupingSort,
      }),
      columnHelper.accessor("eventType", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: CostAlertTable.INTERACTION_FILTER_CLICKED,
                filterKey: "eventType",
                filterValue: getValue(),
              })
            }
          >
            {getStringifiedCostAlertType(getValue())}
          </Text>
        ),
        header: copyText.costAlertTableHeader_eventType,
        meta: { align: "center" },
        size: 60,
      }),
      columnHelper.accessor("expectedRange", {
        cell: ({ getValue }) => {
          const value = getValue();
          return <>{getStringifiedRange(value[0], value[1])}</>;
        },
        header: copyText.costAlertTableHeader_expectedRange,
        meta: { align: "center" },
        size: 60,
      }),
      columnHelper.accessor("actualValue", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.costAlertTableHeader_actualValue,
        meta: { align: "center" },
        size: 40,
      }),
      columnHelper.accessor("delta", {
        cell: ({ row }) => (
          <Text color={theme.feedback_negative}>
            {getStringifiedDelta(row.original.actualValue, {
              lowerBound: row.original.expectedRange[0],
              upperBound: row.original.expectedRange[1],
            })}
          </Text>
        ),
        header: copyText.costAlertTableHeader_delta,
        meta: { align: "center" },
        size: 40,
      }),
      columnHelper.accessor("alertedAt", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: CostAlertTable.INTERACTION_FILTER_CLICKED,
                filterKey: "alertedAt",
                filterValue: getValue(),
              })
            }
          >
            {formatDate(new Date(getValue()), "yyyy-MM-dd")}
          </Text>
        ),
        header: copyText.costAlertTableHeader_alertedAt,
        meta: { align: "center" },
        size: 40,
      }),
      columnHelper.accessor("occurredAt", {
        cell: ({ getValue }) => (
          <>{formatDate(new Date(getValue()), "yyyy-MM-dd")}</>
        ),
        header: copyText.costAlertTableHeader_occurredAt,
        meta: { align: "center" },
        size: 40,
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: function renderButton({ row }) {
          const dropdownOptions = [
            {
              label: copyText.dropdownLabelCreateCase,
              onClick: () =>
                props.onInteraction({
                  type: CostAlertTable.INTERACTION_CREATE_CASE_CLICKED,
                  costAlertID: row.original.id,
                  costAlertEventType: row.original.eventType,
                }),
            },
            {
              label: copyText.dropdownLabelViewAlertDetails,
              onClick: () =>
                props.onInteraction({
                  type: CostAlertTable.INTERACTION_VIEW_ALERT_CLICKED,
                  costAlertID: row.original.id,
                }),
            },
            ...(row.original.alertRuleID ===
              CostAlertLegacySourceRule.LEGACY_BIGQUERY_ANOMALY_ML ||
            row.original.alertRuleID ===
              CostAlertLegacySourceRule.LEGACY_BILLING_ANOMALY_ML
              ? []
              : [
                  {
                    disabled: !gatekeeper.canUpdateAlertRules,
                    label: copyText.dropdownLabelViewSourceAlertRule,
                    onClick: () =>
                      props.onInteraction({
                        type: CostAlertTable.INTERACTION_VIEW_SOURCE_ALERT_RULE_CLICKED,
                        alertRuleID: row.original.alertRuleID,
                      }),
                  },
                ]),
          ];

          return (
            <Dropdown options={dropdownOptions} placement="auto">
              <Button
                iconStart={<Icon icon={faEllipsisV} />}
                secondary
                size="tiny"
              />
            </Dropdown>
          );
        },
        size: 20,
      }),
    ],
    [props.costAlerts]
  );

  const data = useMemo(() => {
    if (props.isLoading) return [];

    const labelMap = props.labelMaps?.BILLING ?? {};

    const reversedLabelMap = Object.entries(labelMap).reduce(
      (accum: { [key: string]: string }, [key, value]) => ({
        ...accum,
        [String(value)]: key,
      }),
      {}
    );

    return props.costAlerts.map((event) => {
      let dimensions = event.dimensions;

      dimensions = dimensions.map((dimension) => {
        return {
          key: reversedLabelMap[dimension.key] ?? dimension.key,
          value: dimension.value,
        };
      });

      return {
        id: event.id,
        alertedAt: event.createdAt,
        alertRuleID: event.alertRuleID,
        actualValue: event.eventValue,
        dimensionGrouping: dimensions,
        delta: getDelta(
          event.eventValue,
          event.expectedValue
            ? event.expectedValue
            : { lowerBound: 0, upperBound: 0 }
        ),
        eventType: event.eventType,
        expectedRange: [
          event.expectedValue ? event.expectedValue.lowerBound : 0,
          event.expectedValue ? event.expectedValue.upperBound : 0,
        ],
        occurredAt: event.eventTime,
        resourceID: event.id,
        resourceName: event.eventType,
        sourceAlertRuleName: getSourceAlertRuleName(event),
      };
    });
  }, [props.costAlerts, props.isLoading === false]);

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [{ id: "alertedAt", desc: true }] }}
      isLoading={props.isLoading}
      resourceType={ResourceType.COST_ALERT}
      showPagination
      sortable
    />
  );

  function customGroupingSort(
    rowA: Row<TableData>,
    rowB: Row<TableData>,
    id: string
  ) {
    const a = rowA.original[id];
    const b = rowB.original[id];
    if (a.length > 0 && b.length === 0) {
      return -1;
    }
    if (b.length > 0 && a.length === 0) {
      return 1;
    }

    if (a.length > 0 && b.length > 0) {
      const dimensionGroupingJoinA = `${a[0].key}=${a[0].value}`;
      const dimensionGroupingJoinB = `${b[0].key}=${b[0].value}`;
      if (
        typeof dimensionGroupingJoinA !== "string" &&
        typeof dimensionGroupingJoinB !== "string"
      )
        return 0;

      if (
        dimensionGroupingJoinA.toLowerCase() ===
        dimensionGroupingJoinB.toLowerCase()
      )
        return 0;
      return dimensionGroupingJoinA.toLowerCase() <
        dimensionGroupingJoinB.toLowerCase()
        ? 1
        : -1;
    }
    return 0;
  }
}

CostAlertTable.INTERACTION_CREATE_CASE_CLICKED =
  `CostAlertTable.INTERACTION_CREATE_CASE_CLICKED` as const;

CostAlertTable.INTERACTION_FILTER_CLICKED =
  `CostAlertTable.INTERACTION_FILTER_CLICKED` as const;

CostAlertTable.INTERACTION_VIEW_ALERT_CLICKED =
  `CostAlertTable.INTERACTION_VIEW_ALERT_CLICKED` as const;

CostAlertTable.INTERACTION_VIEW_EVENT_FEED_CLICKED =
  `CostAlertTable.INTERACTION_VIEW_EVENT_FEED_CLICKED` as const;

CostAlertTable.INTERACTION_VIEW_SOURCE_ALERT_RULE_CLICKED =
  `CostAlertTable.INTERACTION_VIEW_SOURCE_ALERT_RULE_CLICKED` as const;

interface InteractionCreateCaseClicked {
  type: typeof CostAlertTable.INTERACTION_CREATE_CASE_CLICKED;
  costAlertID: string;
  costAlertEventType: CostAlertType;
}

interface InteractionFilterClicked {
  type: typeof CostAlertTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof CostAlertFilters;
  filterValue: string;
}

interface InteractionViewAlertClicked {
  type: typeof CostAlertTable.INTERACTION_VIEW_ALERT_CLICKED;
  costAlertID: string;
}

interface InteractionViewEventFeedClicked {
  type: typeof CostAlertTable.INTERACTION_VIEW_EVENT_FEED_CLICKED;
  alertRuleID: string;
}

interface InteractionViewSourceAlertRuleClicked {
  type: typeof CostAlertTable.INTERACTION_VIEW_SOURCE_ALERT_RULE_CLICKED;
  alertRuleID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CostAlertTable {
  export type Interaction =
    | InteractionCreateCaseClicked
    | InteractionFilterClicked
    | InteractionViewAlertClicked
    | InteractionViewEventFeedClicked
    | InteractionViewSourceAlertRuleClicked;
}

export default CostAlertTable;
