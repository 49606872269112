import { useTheme } from "@emotion/react";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import {
  DataSource,
  DurationType,
  Operator,
  ResourceType,
} from "@ternary/api-lib/constants/enums";
import { CostAlertEntity } from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import { useCaseManagementStore } from "@ternary/api-lib/ui-lib/context/CaseManagementStoreProvider";
import { formatCurrency } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import { format } from "date-fns";
import { keyBy } from "lodash";
import React, { useMemo, useState } from "react";
import { StringParam, useQueryParams } from "use-query-params";
import useGetAnomalyDetail from "../../../api/analytics/hooks/useGetAnomalyDetail";
import useGetLabelMapsByTenantID from "../../../api/core/useGetLabelMapsByTenantID";
import paths from "../../../constants/paths";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useAvailableGlobalDate from "../../../hooks/useAvailableGlobalDate";
import { DateHelper } from "../../../lib/dates";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import DateRangeControls from "../../../ui-lib/components/DateRangeControls/DateRangeControls";
import getMergeState from "../../../utils/getMergeState";
import { useDebounce } from "../../../utils/timers";
import copyText from "../copyText";
import useGetAlertRulesByTenantID from "../hooks/useGetAlertRulesByTenantID";
import useGetCostAlertSummary from "../hooks/useGetCostAlertSummary";
import useGetCostAlertsByTenantID from "../hooks/useGetCostAlertsByTenantID";
import { CostAlert, CostAlertFilters } from "../types";
import {
  getAnomalyDateRange,
  getSourceAlertRuleName,
  getStringifiedDimensions,
  isCostAlert,
  populateCostAlert,
} from "../utils";
import AlertDetailModal from "./AlertDetailModal";
import AlertFeedMeters from "./AlertFeedMeters";
import { Action } from "./AlertRuleForm";
import CostAlertTable from "./CostAlertTable";
import CostAlertTableControls, {
  CostAlertCSVData,
} from "./CostAlertTableControls";

const defaultCostAlerts = [];
const defaultAlertRules = [];

type Interaction =
  | AlertDetailModal.Interaction
  | CostAlertTable.Interaction
  | CostAlertTableControls.Interaction;

interface State {
  costAlertFilters: CostAlertFilters;
  dateRange: Date[];
  duration: DurationType;
  modalKey?: string;
  searchText: string;
}

const initialState: State = {
  costAlertFilters: {
    eventType: null,
    sourceAlertRule: null,
    alertedAt: null,
  },
  dateRange: [],
  duration: DurationType.LAST_THIRTY_DAYS,
  modalKey: undefined,
  searchText: "",
};

export default function CostAlertContainer() {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();
  const navigate = useNavigateWithSearchParams();
  const globalDate = useAvailableGlobalDate();
  const activityTracker = useActivityTracker();
  const caseManagement = useCaseManagementStore();

  //
  // State
  //

  const [searchParamState, setSearchParamState] = useQueryParams({
    alertID: StringParam,
  });

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: alertRules = defaultAlertRules,
    isLoading: isLoadingAlertRules,
  } = useGetAlertRulesByTenantID(authenticatedUser.tenant.fsDocID);

  const {
    data: _costAlerts = defaultCostAlerts,
    isLoading: isLoadingCostAlerts,
  } = useGetCostAlertsByTenantID(authenticatedUser.tenant.fsDocID);

  const { data: labelMaps } = useGetLabelMapsByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  const selectedCostAlert = _costAlerts.find(
    (alert) => alert.id === searchParamState.alertID
  );

  const alertRulesKeyedByID = keyBy(alertRules, "id");

  const alertRule = alertRulesKeyedByID[selectedCostAlert?.alertRuleID ?? ""];

  const populatedCostAlert = selectedCostAlert
    ? populateCostAlert(selectedCostAlert, alertRule)
    : undefined;

  const ruleFilters: QueryFilter[] =
    populatedCostAlert?.sourceAlertRule.filters ?? [];

  const alertFilters: QueryFilter[] = useMemo(() => {
    if (!selectedCostAlert) return [];
    if (!isCostAlert(selectedCostAlert)) return [];

    // If value is null operator should be NOT_SET
    return selectedCostAlert.dimensions.map((dimension) => {
      return {
        name: dimension.key,
        operator: dimension.value ? Operator.EQUALS : Operator.NOT_SET,
        values: dimension.value ? [dimension.value] : null,
      };
    });
  }, [selectedCostAlert]);

  const anomalyDateRange = useMemo(() => {
    if (!populatedCostAlert) return [];

    if (globalDate.date) {
      return globalDate.date;
    }

    return getAnomalyDateRange(
      populatedCostAlert.eventTime,
      populatedCostAlert.sourceAlertRule.timeGranularity
    );
  }, [selectedCostAlert, globalDate]);

  const { data: alertCostData = [], isLoading: isLoadingAlertCostData } =
    useGetAnomalyDetail(
      {
        queryFilters: [...ruleFilters, ...alertFilters],
        dateRange: anomalyDateRange,
        dataSource: DataSource.BILLING,
        measures: [populatedCostAlert?.sourceAlertRule.measure ?? "cost"],
        granularity: populatedCostAlert?.sourceAlertRule.timeGranularity,
      },
      {
        enabled: !!selectedCostAlert && anomalyDateRange.length === 2,
      }
    );

  let dateRange =
    state.dateRange[0] && state.dateRange[1]
      ? state.dateRange
      : getCubeDateRangeFromDurationType(state.duration);

  dateRange = globalDate.date ?? dateRange;

  const { data: alertSummary, isLoading: isLoadingAnomalySummary } =
    useGetCostAlertSummary({
      dateRange: dateRange,
    });

  //
  // Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case AlertDetailModal.INTERACTION_CREATE_CASE_CLICKED:
      case CostAlertTable.INTERACTION_CREATE_CASE_CLICKED: {
        activityTracker.captureAction(
          interaction.type === CostAlertTable.INTERACTION_CREATE_CASE_CLICKED
            ? actions.CLICK_ANOMALY_DETECTION_CREATE_CASE_ANOMALY_MENU
            : actions.CLICK_ANOMALY_DETECTION_CREATE_CASE_DETAIL_MODAL
        );
        caseManagement.set({
          isSideDrawerOpen: true,
          selectedResourceID: interaction.costAlertID,
          isResourceSelectionMode: false,
          selectedResourceName: interaction.costAlertEventType,
          selectedResourceType: ResourceType.COST_ALERT,
        });
        break;
      }
      case CostAlertTable.INTERACTION_VIEW_ALERT_CLICKED: {
        activityTracker.captureAction(
          actions.CLICK_ANOMALY_DETECTION_ALERT_EVENT_TO_DISPLAY
        );
        setSearchParamState({
          alertID: interaction.costAlertID,
        });
        break;
      }
      case CostAlertTable.INTERACTION_VIEW_EVENT_FEED_CLICKED: {
        navigate(
          paths._alertRuleFeed.replace(":alertRuleID", interaction.alertRuleID)
        );
        break;
      }
      case CostAlertTable.INTERACTION_VIEW_SOURCE_ALERT_RULE_CLICKED: {
        navigate(paths._alertTracking, {
          searchParams: {
            tab: "alertRules",
            actionPanelKey: Action.UPDATE,
            alertRuleID: interaction.alertRuleID,
          },
        });
        break;
      }
      case CostAlertTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...state.costAlertFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        mergeState({ costAlertFilters: nextFilters });
        break;
      }
      case CostAlertTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...state.costAlertFilters };

        nextFilters[interaction.filterKey] = null;

        mergeState({ costAlertFilters: nextFilters });

        break;
      }
      case CostAlertTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        mergeState({ searchText: interaction.searchText });
        break;
      }
      case AlertDetailModal.INTERACTION_INVESTIGATE_CLICKED: {
        activityTracker.captureAction(
          actions.CLICK_ANOMALY_DETECTION_INVESTIGATE,
          { value: searchParamState.alertID }
        );

        navigate(paths._reportBuilderNew, {
          state: {
            from: paths._alertTracking,
            runQueryTriggered: true,
            report: {
              dataSource: DataSource.BILLING,
              dimensions: interaction.dimensions,
              durationType: DurationType.CUSTOM,
              endDate: interaction.endDate,
              filters: interaction.filters,
              measures: interaction.measures,
              startDate: interaction.startDate,
            },
            title: copyText.anomalyDetectionPageTitle,
          },
        });
        break;
      }
    }
  }

  //
  // Render
  //

  const costAlerts = _costAlerts.map(
    (alert) => {
      const alertRule = alertRulesKeyedByID[alert.alertRuleID];

      return {
        ...alert,
        ...(alertRule ? { alertRule } : {}),
      };
    },
    [_costAlerts]
  );

  const debouncedSearchText = useDebounce(state.searchText);

  const filteredCostAlerts = useMemo(() => {
    return getFilteredCostAlerts({
      costAlerts,
      costAlertFilters: state.costAlertFilters,
      searchText: debouncedSearchText,
    });
  }, [state.costAlertFilters, costAlerts, debouncedSearchText]);

  const csvData = useMemo(() => getCSVData(costAlerts), [_costAlerts]);

  function renderModal() {
    if (!populatedCostAlert) return null;

    return (
      <AlertDetailModal
        alert={populatedCostAlert}
        isOpen={true}
        isLoading={isLoadingAlertCostData}
        costData={alertCostData}
        labelMaps={labelMaps}
        onClose={() => {
          setSearchParamState({
            alertID: undefined,
          });
        }}
        onInteraction={handleInteraction}
      />
    );
  }

  //
  // JSX
  //

  return (
    <Flex direction="column">
      <Flex
        justifyContent="flex-end"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={state.duration}
          hiddenOptions={[DurationType.QUARTER_TO_DATE]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            mergeState({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    dateRange: newDateRange,
                  }
                : {
                    dateRange: [],
                  }),
            });
          }}
        />
      </Flex>
      <AlertFeedMeters
        isAllAlerts
        isLoading={isLoadingAnomalySummary}
        negativeDelta={alertSummary?.negativeDelta}
        percentageAnomalous={alertSummary?.percentageAnomalous}
        positiveDelta={alertSummary?.positiveDelta}
        totalCostAlerts={alertSummary?.totalAlerts}
      />
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginBottom={theme.space_sm}
        padding={theme.space_md}
      >
        <CostAlertTableControls
          csvData={csvData}
          eventFilters={state.costAlertFilters}
          debouncedSearchText={debouncedSearchText}
          searchText={state.searchText}
          onInteraction={handleInteraction}
        />
      </Box>
      <Box>
        <CostAlertTable
          costAlerts={filteredCostAlerts}
          isLoading={isLoadingCostAlerts || isLoadingAlertRules}
          labelMaps={labelMaps}
          onInteraction={handleInteraction}
        />
      </Box>
      {renderModal()}
    </Flex>
  );
}

function nullOrIsSame(filter: string | null, b: string) {
  if (filter === null) return true;
  return filter.toLowerCase().trim() === b.toLowerCase().trim();
}

function costAlertPassesFilters(params: {
  costAlert: CostAlert;
  filters: CostAlertFilters;
}) {
  if (
    !nullOrIsSame(
      params.filters.sourceAlertRule,
      getSourceAlertRuleName(params.costAlert)
    )
  ) {
    return false;
  }
  if (!nullOrIsSame(params.filters.eventType, params.costAlert.eventType)) {
    return false;
  }
  if (!nullOrIsSame(params.filters.alertedAt, params.costAlert.createdAt)) {
    return false;
  }

  return true;
}

function costAlertHasSearchText(costAlert: CostAlert, searchText: string) {
  if (searchText.trim() === "") return true;

  const searchableText = [
    costAlert.alertRuleID,
    format(new Date(costAlert.createdAt), "yyyy-MM-dd"),
    format(new Date(costAlert.eventTime), "yyyy-MM-dd"),
    costAlert.eventType,
    ...(costAlert.alertRule ? [costAlert.alertRule.name] : []),
    ...costAlert.dimensions.map((dimension) => dimension.value),
    ...costAlert.dimensions.map((dimension) => dimension.key),
  ]
    .join(" ")
    .toLowerCase();

  return searchableText.includes(searchText.toLowerCase());
}

const csvAccessors = [
  "id",
  "sourceAlertRule",
  "eventType",
  "expectedRange",
  "actualValue",
  "alertedAt",
  "occurredAt",
  "dimensions",
] as const;

function getCSVData(alerts: CostAlertEntity[]): CostAlertCSVData {
  if (!alerts.length) {
    return { headers: [], rows: [] };
  }

  const rows = alerts.map((event) => {
    const expectedRange = `${formatCurrency({
      number: event.expectedValue.lowerBound,
    })}- ${formatCurrency({
      number: event.expectedValue.upperBound,
    })}`;

    return {
      id: event.id,
      actualValue: formatCurrency({ number: event.eventValue }),
      alertedAt: event.createdAt,
      dimensions: getStringifiedDimensions(event.dimensions).join(", "),
      eventType: event.eventType,
      expectedRange: expectedRange,
      occurredAt: event.eventTime,
      sourceAlertRule: event.alertRuleID,
    };
  });

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `costAlertTableHeader_${csvAccessor}`;

    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredAlertParams = {
  costAlerts: CostAlert[];
  costAlertFilters: CostAlertFilters;
  searchText: string;
};

function getFilteredCostAlerts(params: GetFilteredAlertParams): CostAlert[] {
  return params.costAlerts.filter((alert) => {
    if (
      !costAlertPassesFilters({
        costAlert: alert,
        filters: params.costAlertFilters,
      })
    ) {
      return false;
    }

    if (!costAlertHasSearchText(alert, params.searchText)) {
      return false;
    }

    return true;
  });
}
