import externalLinks from "@/constants/externalLinks";
import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import isEmail from "validator/lib/isEmail";
import { FormField } from "../../../../../ui-lib/components/Form";
import TextInput from "../../../../../ui-lib/components/TextInput";
import copyText from "../../copyText";
import { FormProps } from "./types";

const validators = {
  apiToken: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  email: ({ value }: { value: string }) => {
    return !isEmail(value) ? copyText.errorInputFieldRequired : undefined;
  },
  issueTransitionID: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  projectId: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
};

export default function JiraIntegrationForm(props: FormProps) {
  const theme = useTheme();

  const { form } = props;

  return (
    <Box padding={theme.space_xxs}>
      {form.options.defaultValues?.projectID.length === 0 && (
        <form.Field
          name="projectID"
          validators={{
            onChange: validators.projectId,
            onMount: validators.projectId,
          }}
          children={(field) => {
            const shouldShowError =
              field.state.meta.isTouched && field.state.meta.errors.length > 0;

            return (
              <FormField
                input={TextInput}
                errorCaption={
                  shouldShowError
                    ? field.state.meta.errors.join(", ")
                    : undefined
                }
                label={copyText.projectIDInputLabel}
                required
                value={field.state.value}
                variant={shouldShowError ? "danger" : undefined}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        />
      )}
      <form.Field
        name="email"
        validators={{
          onChange: validators.email,
          onMount: validators.email,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              autoFocus
              label={copyText.emailInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="apiToken"
        validators={{
          onChange: validators.apiToken,
          onMount: validators.apiToken,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.apiTokenInputLabel}
              required
              placeholder={form.options.defaultValues?.apiToken}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="issueTransitionID"
        validators={{
          onChange: validators.issueTransitionID,
          onMount: validators.issueTransitionID,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              caption={copyText.issueTransitionIDInputCaption}
              label={copyText.issueTransitionIDInputLabel}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <Flex alignItems="center">
        <Text whiteSpace="pre">{copyText.jiraDocumentationCaption}</Text>
        <a
          href={externalLinks.readmeJiraIntegrationDocumentation}
          rel="noreferrer"
          target="_blank"
        >
          {"here."}
        </a>
      </Flex>
    </Box>
  );
}
